import * as paymentType from './payment.json'
import isBnn from './isBnn'

export default {
  [paymentType.P_KBANK_DEPOSIT]: require('@/assets/icon-bank/PNG/logo-installment-visa-master-card.png'),
  [paymentType.P_KBANK_FULL]: require('@/assets/icon-bank/PNG/logo-installment-visa-master-card.png'),
  [paymentType.P_KBANK_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-KBANK.png'),
  [paymentType.P_KBANK_QR_FULL]: require('@/assets/icon-bank/PNG/logo-installment-thai-qr.png'),
  [paymentType.P_KBANK_QR_DEPOSIT]: require('@/assets/icon-bank/PNG/logo-installment-thai-qr.png'),

  [paymentType.P_KRUNGSRI_DEPOSIT]: require('@/assets/icon-bank/PNG/logo-installment-KCC.png'),
  [paymentType.P_KRUNGSRI_FULL]: require('@/assets/icon-bank/PNG/logo-installment-KCC.png'),
  [paymentType.P_KRUNGSRI_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-KCC.png'),
  [paymentType.P_KRUNGSRI_FIRST_CHOICE_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-KFC.png'),

  [paymentType.P_2C2P_DEPOSIT]: require('@/assets/icon-bank/PNG/logo-installment-visa-master-card.png'),
  [paymentType.P_2C2P_FULL]: require('@/assets/icon-bank/PNG/logo-installment-visa-master-card.png'),
  [paymentType.P_2C2P_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-visa-master-card.png'),
  [paymentType.P_2C2P_CITYBANK_INSTALLMENT]: require('@/assets/icon-bank/SVG/Citi.svg'),
  [paymentType.P_2C2P_QR_REDIRECT]: require('@/assets/icon-bank/PNG/logo-installment-thai-qr.png'),
  [paymentType.P_2C2P_UOB_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-UOB.png'),

  [paymentType.P_SCBEASY_FULL]: require('@/assets/icon-bank/PNG/logo-installment-SCB.png'),
  [paymentType.P_SCBEASY_CREDIT_FULL]: require('@/assets/icon-bank/PNG/logo-installment-SCB.png'),
  [paymentType.P_SCBEASY_DEPOSIT]: require('@/assets/icon-bank/PNG/logo-installment-SCB.png'),
  [paymentType.P_SCBEASY_CREDIT_DEPOSIT]: require('@/assets/icon-bank/PNG/logo-installment-SCB.png'),
  [paymentType.P_SCBEASY_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-SCB.png'),

  [paymentType.P_KTC_REDIRECT]: require('@/assets/icon-bank/PNG/logo-installment-KTC.png'),
  [paymentType.P_KTC_INSTALLMENT]: require('@/assets/icon-bank/PNG/logo-installment-KTC.png'),

  [paymentType.P_PAY_AT_STORE]: isBnn
    ? require('@/assets/icon-bank/SVG/bnn-logo-small.svg')
    : require('@/assets/icon-bank/SVG/studio7-logo-small.svg'),
}
