<template>
  <div>
    <div v-if="isShowCoupon" class="coupon-code">
      <div class="input-coupon-code">
        <v-text-field
          :color="colorBnn['color-bnn']"
          :disabled="validCoupon ? true : false"
          v-model="form.couponCode"
          background-color="white"
          data-pptr="couponCode"
          class="input-customer text-sm mt-2"
          :error="$v.form.couponCode.$error"
          type="text"
          ref="coupon_code"
          placeholder="กรอกโค้ดส่วนลด"
        >
          <template v-slot:append>
            <v-icon v-if="validCoupon" color="green">
              mdi-check-bold
            </v-icon>
            <v-icon v-else-if="coupon.isError" color="red">
              mdi-close
            </v-icon>
          </template>
        </v-text-field>
      </div>
      <div class="use-code">
        <Button
          v-if="validCoupon"
          @click="clearCoupon"
          block
          depressed
          color="color-bnn-gray-light-2
        
        "
          title="ยกเลิก"
        />

        <Button
          v-else
          @click="checkValidateCoupon"
          block
          depressed
          color="color-bnn"
          title="ใช้โค้ด"
        />
      </div>
    </div>
    <div v-if="msgError" class="coupon-msg-error">
      <span>{{ msgError }}</span>
    </div>
    <v-row
      v-bind="{
        justify: 'between',
        align: 'start',
      }"
    >
      <v-col
        cols="auto"
        v-if="isShowMainProductImage && mainProductImage"
      >
        <div class="product-item">
          <div class="product-img">
            <img :src="mainProductImage" />
          </div>
        </div>
      </v-col>
      <v-col>
        <h3>{{ productName }}</h3>
        <div>
          SKU: <span>{{ productSKU }}</span>
        </div>
      </v-col>
      <v-col cols="auto" class="text-right price-text font-bold">
        ฿{{ productPrice | currency2 }}
      </v-col>
    </v-row>

    <hr v-if="bundles.length > 0" class="my-3 gray" />

    <v-row
      v-if="bundles.length > 0"
      v-bind="{
        justify: 'between',
        align: 'center',
      }"
    >
      <v-col>
        <!-- <h2>อุปกรณ์เสริมที่ซื้อพร้อมกัน</h2> -->
        <h3>รายการสินค้าเพิ่มเติม</h3>
      </v-col>
    </v-row>

    <v-row
      v-for="(item, index) in bundles"
      :key="index"
      v-bind="{
        justify: 'between',
      }"
      class="bundle-list"
    >
      <v-col cols="8">
        <div class="sku-product">
          <img :src="item.image_url" />
          <div>{{ item.name }}</div>
        </div>
      </v-col>
      <template v-if="+item.price_srp === 0">
        <v-col class="price-bundle-text font-bold free">
          <div>ฟรี</div>
        </v-col>
      </template>
      <template v-else>
        <v-col cols="4" class="price-bundle-text font-bold">
          <div v-if="isDelivery">
            ฿{{ item.price_ship | currency2 }}
          </div>
          <div v-else>฿{{ item.price_store | currency2 }}</div>
          <div
            v-if="isDelivery && item.price_ship !== item.price_srp"
            class="text-line-through normal-price"
          >
            ฿{{ item.price_srp | currency2 }}
          </div>
          <div
            v-else-if="
              !isDelivery && item.price_store !== item.price_srp
            "
            class="text-line-through normal-price"
          >
            ฿{{ item.price_srp | currency2 }}
          </div>
        </v-col>
      </template>
    </v-row>

    <!-- <hr v-if="additionalList.length > 0" class="my-3 gray" />

    <v-row
      v-if="additionalList.length > 0"
	  v-bind="{
		justify: 'between',
		align: 'center',
	  }"
    >
      <v-col><h2>Additional Product</h2></v-col>
    </v-row>
    <v-row
      v-for="(item, index) in additionalList"
      :key="index"
	  v-bind="{ justify: 'between' }"
      class="bundle-list"
    >
      <v-col cols="8">
        <div class="sku-product">
          <img :src="item.imageUrl" />
          <div>{{ item.name }}</div>
        </div>
      </v-col>
      <v-col cols="4" class="price-bundle-text font-bold">
        <div v-if="isDelivery">
          ฿{{ item.price_ship | currency2 }}
        </div>
        <div v-else-if="isStore">
          ฿{{ item.price_store | currency2 }}
        </div>
        <div
          v-if="isDelivery && item.price_ship !== item.price_srp"
          class="text-line-through normal-price"
        >
          ฿{{ item.price_srp | currency2 }}
        </div>
        <div
          v-else-if="isStore && item.price_store !== item.price_srp"
          class="text-line-through normal-price"
        >
          ฿{{ item.price_srp | currency2 }}
        </div>
      </v-col>
    </v-row> -->

    <!-- <hr class="my-3 gray" /> -->

    <!-- <v-row 
	v-bind="{
	justify: 'between',
	align: 'center'
	}"
	>
      <v-col><h2>ราคาทั้งหมด</h2></v-col>
    </v-row> -->

    <template v-if="isShowPayment">
      <hr class="my-3 gray" />

      <v-row
        v-if="!isDeposit"
        v-bind="{
          justify: 'between',
          align: 'center',
        }"
      >
        <v-col>
          <h3>ยอดรวมสุทธิ</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-normal-text">
            ฿{{ finalPrice | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <v-row
        v-if="discountAmount > 0"
        v-bind="{
          justify: 'between',
          align: 'center',
        }"
      >
        <v-col>
          <h3>
            {{ titleSpecialDiscount }}
          </h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-normal-text">
            - ฿{{ discountAmount | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <v-row
        v-if="additionalDiscount > 0"
        v-bind="{
          justify: 'between',
          align: 'center',
        }"
      >
        <v-col>
          <h3>
            {{ additionalDiscountLabel }}
          </h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-normal-text">
            - ฿{{ additionalDiscount | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <v-row
        v-if="couponCode"
        v-bind="{
          justify: 'between',
          align: 'center',
        }"
      >
        <v-col>
          <h3>คูปองที่ใช้</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="badge-coupon">
            {{ couponCode }}
          </h3>
        </v-col>
      </v-row>

      <v-row
        v-if="isDeposit"
        v-bind="{
          justify: 'between',
          align: 'center',
        }"
      >
        <v-col cols="auto">
          <h3>
            ยอดมัดจำ
            <template v-if="data.status !== 'complete'">
              (ชำระแล้ว)
            </template>
          </h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-normal-text">
            ฿{{ depositAmount | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <v-row
        v-if="remainingAmount > 0"
        v-bind="{
          justify: 'between',
          align: 'center',
        }"
      >
        <v-col>
          <h3>ยอดชำระคงเหลือ</h3>
        </v-col>
        <v-col class="text-right">
          <h3 class="price-summary-text">
            ฿{{ remainingAmount | currency2 }}
          </h3>
        </v-col>
      </v-row>

      <template v-if="isShowShipping">
        <hr class="my-3 gray" />

        <v-row>
          <v-col>
            <h3>{{ fullName }}</h3>
          </v-col>
        </v-row>

        <template v-if="!isDeposit">
          <v-row>
            <v-col cols="12">
              <h3 class="mb-2">จัดส่งตามที่อยู่</h3>
              <span v-html="fullAddress"></span>
            </v-col>
            <v-col cols="12">
              <h3 class="mb-2">จะได้รับสินค้าภายในวันที่</h3>
              {{ shippingDate }}
            </v-col>
          </v-row>

          <!-- <v-row>
          <v-col>
            <span v-html="fullAddress"></span>
          </v-col>
          <v-col>
            <div>
              <span>
                <b>บ้าน/อาคาร เลขที่:</b> {{ data.address }}
              </span>
            </div>
            <div>
              <span> <b>แขวง/ตำบล:</b> {{ data.sub_district }} </span>
            </div>
            <div>
              <span> <b>เขต/อำเภอ:</b> {{ data.district }} </span>
            </div>
            <div>
              <span> <b>จังหวัด:</b> {{ data.province }} </span>
            </div>
            <div>
              <span> <b>รหัสไปรษณีย์:</b> {{ data.postcode }} </span>
            </div>
          </v-col>
        </v-row> -->
        </template>

        <template v-if="isDeposit">
          <v-row>
            <v-col>
              <h3>ชำระและรับสินค้าได้ที่สาขา</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div>
                <span> <b>สาขา:</b> {{ receiveBranchName }} </span>
              </div>
              <div>
                <span> <b>วันที่:</b> {{ shippingDate }} </span>
              </div>
              <!-- ? Notes: Hide this -->
              <!-- <div>
              <span> <b>เวลา:</b> {{ receiveTime }} </span>
            </div> -->
              <div class="text-red" v-if="msgErrorReceiveBranch">
                {{ msgErrorReceiveBranch }}
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-red">
              *การจองและรับสินค้าที่สาขา บริษัทฯ
              ขอเช็คเปิดเครื่องเพื่อการรับประกันสินค้าทุกกรณี
              และขอสงวนสิทธิ์ยกเลิกคำสั่งซื้อหากลูกค้าไม่ประสงค์จะเปิดเช็คเครื่อง
            </v-col>
          </v-row>
        </template>

        <template v-if="status2nd === 'complete'">
          <hr class="my-3 gray" />
          <v-row>
            <v-col>
              <h3 class="text-green">การชำระเงินเสร็จสมบูรณ์</h3>
            </v-col>
          </v-row>
        </template>
      </template>

      <SummaryFooter
        v-if="isShowSummaryFooter"
        :netPriceSummary="finalPrice"
        :couponDiscount="0"
        :onTopDiscount="0"
        :remainingAmount="remainingAmount"
        class="d-sm-none"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Button from '@/components/bnn/Button'
import colorBnn from '@/config/color'
import { validationMixin } from 'vuelidate'
import { maxLength } from 'vuelidate/lib/validators'
import branchTypes from '@/config/branchTypes'
import SummaryFooter from '@/components/bnn/SummaryFooter'

export default {
  mixins: [validationMixin],

  props: {
    isShowSummaryFooter: {
      type: Boolean,
      default: true,
    },
    isShowMainProductImage: {
      type: Boolean,
      default: false,
    },
    isShowShipping: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    ...mapState({
      isLoading: state => state.remaining.isLoading,
      data: state => state.remaining?.data || state.order.data,
      coupon: state => state.remaining?.coupon,
    }),

    productName() {
      return (
        this.data?.product_main?.name ?? this.data?.product_name ?? ''
      )
    },

    productPrice() {
      return (
        this.data?.product_main?.price ??
        this.data?.product_price ??
        0
      )
    },

    productSKU() {
      return (
        this.data?.product_main?.sku ?? this.data.product_sku ?? ''
      )
    },

    validCoupon() {
      return !this.coupon.isError && this.coupon.code
    },

    bundles() {
      return (
        this.data?.bundle_products?.filter(
          i => i.type !== 'delivery',
        ) ?? []
      )
    },

    isShowPayment() {
      return true
      // return (
      //   this.data?.is_deposit &&
      //   this.data?.is_delivery &&
      //   !this.data?.status_2nd
      // )
    },

    isShowCoupon() {
      // TODO: Hide Coupon
      // if (
      //   !this.data?.is_deposit ||
      //   this.data?.status_2nd === 'complete' ||
      //   this.data?.coupon_used !== null
      // ) {
      //   return false
      // }
      // if (this.data?.is_deposit && !this.data?.is_delivery) {
      //   return false
      // }
      // return true
      return false
    },

    fullName() {
      return this.data?.full_name ?? '-'
    },

    fullAddress() {
      return `บ้าน/อาคาร เลขที่ ${this.data?.address} แขวง/ตำบล ${this.data?.sub_district} เขต/อำเภอ ${this.data?.district} จังหวัด ${this.data?.province} รหัสไปรษณีย์ ${this.data?.postcode}`
    },

    receiveBranchName() {
      return this.data?.receive_branch_name ?? 'ไม่พบข้อมูล'
    },

    receiveDate() {
      return this.data?.receive_date ?? 'ไม่พบข้อมูล'
    },

    shippingDate() {
      return this.data?.shipping_date ?? 'ไม่พบข้อมูล'
    },

    receiveTime() {
      return this.data?.receive_time ?? 'ไม่พบข้อมูล'
    },

    msgErrorReceiveBranch() {
      let error =
        (this.data?.is_deposit && this.data?.is_delivery) ||
        !this.data?.receive_branch_name
      return error
        ? 'เกิดข้อผิดพลาดระหว่างการเลือกสาขา กรุณาติดต่อเจ้าหน้าที่เพื่อดำเนินการต่อไป'
        : ''
    },

    status2nd() {
      return this.data?.status_2nd
    },

    isDelivery() {
      return this.data?.is_delivery
    },

    isDeposit() {
      return this.data?.is_deposit || this.data?.payment_is_deposit
    },

    isPayAtStoreTradeIn() {
      return (
        this.data?.pay_at_store_detail?.order_type == 'with_tradein'
      )
    },

    isPayAtStorePromotion() {
      return this.data?.pay_at_store_detail?.order_type == 'with_plan'
    },

    depositAmount() {
      return this.data?.deposit ?? this.data?.total_deposit ?? 0
    },

    finalPrice() {
      return this.isPayAtStoreTradeIn || this.isPayAtStorePromotion
        ? this.data?.pay_at_store_detail?.paid_amount ?? 0
        : this.data?.total_price ?? 0
    },

    discountAmount() {
      return this.data?.discount ?? 0
    },

    titleSpecialDiscount() {
      return this.data?.special_discount_title ?? 'ส่วนลด'
    },

    additionalDiscountLabel() {
      return this.isPayAtStoreTradeIn
        ? 'ส่วนลดจาก Trade-in'
        : this.isPayAtStorePromotion
        ? 'ส่วนลดจากโปรโมชั่นเบอร์ทรู'
        : 'ส่วนลดเพิ่มเติม'
    },

    additionalDiscount() {
      return this.isPayAtStoreTradeIn
        ? Number(this.data?.pay_at_store_detail?.voucher_amount)
        : this.isPayAtStorePromotion
        ? // ? this.data?.pay_at_store_detail?.promotion_discount
          this.data?.pay_at_store_detail?.with_plan_promotion_discount
        : 0
    },

    couponDiscount() {
      return 0
    },

    couponCode() {
      return this.data?.coupon_code ?? ''
    },

    onTopDiscount() {
      return this.data?.on_top_discount ?? 0
    },

    remainingAmount() {
      return this.data?.remaining ?? this.data?.amount_remain ?? 0
    },

    mainProductImage() {
      return (
        this.data?.product_main?.image_url ??
        this.data.product.image_url ??
        null
      )
    },
    decryptedData() {
      return this.$store.getters['user/getData']
    },
  },

  data() {
    return {
      colorBnn,
      branchTypes,
      form: {
        couponCode: '',
      },
      formCheckSummary: {
        first_name: null,
        last_name: null,
        phone: null,
        email: null,
        address: null,
        sub_district: null,
        district: null,
        province: null,
        postcode: null,
        // Notes: Hide default value from local storage
        // first_name: localStorage.getItem('firstName') || 'first_name',
        // last_name: localStorage.getItem('lastName') || 'last_name',
        // phone: localStorage.getItem('phoneNumber') || 'phone',
        // email: localStorage.getItem('email') || 'email',
        // address: localStorage.getItem('address') || 'address',
        // sub_district:
        //   localStorage.getItem('sub_district') || 'sub_district',
        // district: localStorage.getItem('district') || 'district',
        // province: localStorage.getItem('province') || 'province',
        // postcode: localStorage.getItem('postcode') || 'postcode',
        product_id: '',
        bundle_products: [],
        delivery: false,
        is_delivery: false,
      },
    }
  },

  validations: {
    form: { couponCode: { maxLength: maxLength(5) } },
  },

  components: {
    Button,
    SummaryFooter,
  },

  mounted() {
    // this.$refs.cartSummary.checkSummary()

    // Notes: Prepare form check summary
    this.prepareFormCheckSummary()
  },

  methods: {
    prepareFormCheckSummary() {
      this.$store.dispatch('user/fetchData')

      this.formCheckSummary = {
        ...this.formCheckSummary,
        first_name: this.decryptedData?.first_name || null,
        last_name: this.decryptedData?.last_name || null,
        phone: this.decryptedData?.phone || null,
        email: this.decryptedData?.email || null,
        address: this.decryptedData?.address || null,
        sub_district: this.decryptedData?.sub_district || null,
        district: this.decryptedData?.district || null,
        province: this.decryptedData?.province || null,
        postcode: this.decryptedData?.postcode || null,
      }
    },

    async checkValidateCoupon() {
      if (this.form.couponCode.trim()) {
        const { orderId } = this.$route.params
        const payload = {
          orderId: orderId,
          couponCode: this.form.couponCode,
        }
        await this.$store.dispatch(
          'remaining/checkCouponCode',
          payload,
        )
        // this.$refs.cartSummary.checkSummary()
      }
    },

    clearCoupon() {
      this.couponCode = ''
      this.$store.commit('remaining/resetCouponCode')
      // this.$refs.cartSummary.checkSummary()
    },
  },
}
</script>

<style lang="stylus" scoped>

.coupon-code
    display: flex
    justify-content: center
    align-items: center
    gap: 0 16px
    .input-coupon-code
        width:70%
    .use-code
        width: 30%

.bundle-list
    font-size: 0.95rem

.price-text
    font-size: 1.15rem
    color:  $color-text-price
    text-align: right

.price-bundle-text
    color:  $color-text-price
    text-align: right
    display: flex
    flex-direction: column
    justify-content: flex-start

.price-bundle-text.free
	color: #4CAF50;

.price-normal-text
    color:  $color-normal-price

.price-summary-text
    color:  $color-text-price

.payment-type-text
    color: $color-bnn-orange

.text-red
    color: $color-bnn-red-2

.sku-product
  display: flex
  gap 0 16px
  align-items: flex-start
  img
    width: 48px
    height: 48px
    object-fit: contain

.coupon-msg-error
  color: $color-bnn-red
  margin-top: -1rem
  font-size: 1rem

.normal-price
  color: $color-bnn-gray-medium-2
  font-size: 0.85rem
  text-align: right

.product-item
  display: flex
  gap: 12px

.product-img {
  width: 48px
  height: 48px

  > img {
	width: 100%
	height: 100%
	object-fit: contain
  }
}
</style>
