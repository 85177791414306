<template>
  <div v-if="isLoading">
    <Load />
    <div class="full-h-screen"></div>
  </div>

  <div v-else>
    <div v-if="isBnn" class="full-page">
      <div class="remaining-cart-pre-order">
        <v-container>
          <v-row
            v-bind="{ justify: 'center', align: 'center' }"
            class="mb-6"
          >
            <v-col class="text-center">
              <h1>รายละเอียดคำสั่งซื้อ</h1>
              <div>
                <span class="ref-text"
                  >เลขที่ใบสั่งจอง : {{ refId }}</span
                >
              </div>
              <div>
                <span class="ref-text" v-if="isDeposit">
                  เลขที่ใบรับของ (บิลมัดจำ) :
                  {{ depositNumber }}</span
                >
              </div>
            </v-col>
            <v-col v-if="isBarcode" cols="12" class="text-center">
              <img :src="barcode" width="256" height="40" />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6" sm="12" class="gallery">
              <template v-if="thumbnailList.length > 0">
                <PreviewImageSwiper :medias="thumbnailList" />
              </template>
            </v-col>
            <v-col md="6" sm="12" class="cart-order">
              <RemainingCartOrder />
            </v-col>
          </v-row>

          <template v-if="isShowPayment">
            <hr class="gray hr-section" />

            <!-- Payment -->

            <div>
              <h4 class="text-lg payment-label">
                ชำระเงินแบบเต็มจำนวน
              </h4>
              <div class="chanel-payment-grid">
                <ButtonPayment
                  data-pptr="payment_type_id"
                  :hasError="isErrorRequirePayment"
                  @click="paymentId = 'kbank'"
                  name="ชำระเงินด้วยบัตร VISA/Master Card"
                  :image="iconBank[4]"
                  :selected="paymentId === 'kbank'"
                  :key="'kbank'"
                />
              </div>
            </div>

            <div>
              <h4 class="text-lg payment-label">
                ผ่อนชำระด้วยบัตรเครดิต
              </h4>
              <div class="chanel-payment-grid">
                <ButtonPayment
                  data-pptr="payment_type_id"
                  :hasError="isErrorRequirePayment"
                  @click="paymentId = '2c2p'"
                  name="ผ่อนชำระเงินด้วยบัตร VISA/Master Card"
                  :image="iconBank[9]"
                  :selected="paymentId === '2c2p'"
                  :key="'2c2p'"
                />
                <ButtonPayment
                  data-pptr="payment_type_id"
                  :hasError="isErrorRequirePayment"
                  @click="paymentId = 'kbank-installment'"
                  name="ผ่อนชำระเงินด้วยบัตรเครดิตกสิกร"
                  :image="iconBank[1]"
                  :selected="paymentId === 'kbank-installment'"
                  :key="'kbank-installment'"
                />
              </div>
            </div>
            <!--End Payment -->

            <div class="checkout-action">
              <v-container class="d-flex justify-center">
                <div class="action">
                  <div class="d-flex justify-center">
                    <Button
                      @click="onSubmit()"
                      :disabled="disabledButton"
                      :loading="loadingPayment"
                      width="100%"
                      depressed
                      color="color-bnn"
                      title="ตรวจสอบคำสั่งซื้อและชำระเงิน"
                    />
                  </div>
                </div>
              </v-container>
            </div>
          </template>
        </v-container>
      </div>
    </div>
    <OrderDetailStudio7 v-else>
      <template v-slot:appendOrderDetail>
        <div v-if="isBarcode">
          <img :src="barcode" width="256" height="40" />
        </div>
      </template>
    </OrderDetailStudio7>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Load from './Load'
import PreviewImageSwiper from '@/components/bnn/PreviewImageSwiper'
import RemainingCartOrder from '@/components/bnn/RemainingCartOrder'
import ButtonPayment from '@/components/bnn/ButtonPayment'
import Button from '@/components/bnn/Button'
import { showKBankEmbedUI } from '@/utils/kbank'
import iconBank from '@/config/iconBank'
import axios from 'axios'
import { resizeImage } from '@/utils/helpers'
import isBnn from '@/config/isBnn'

export default {
  components: {
    PreviewImageSwiper,
    RemainingCartOrder,
    ButtonPayment,
    Button,
    Load,

    OrderDetailStudio7: () =>
      import('@/components/studio7/OrderDetailStudio7'),
  },

  computed: {
    ...mapState({
      remainLoading: state => state.order?.isLoading, // previous : state.remaining?.isLoading,
      data: state => state.order.data, // previous : state.remaining.data
      coupon: state => state.remaining.coupon, // previous : state.remaining.coupon
    }),

    isLoading() {
      return this.remainLoading
    },

    refId() {
      return this.data?.order
    },

    isDeposit() {
      return this.data?.is_deposit
    },

    depositNumber() {
      return this.data?.deposit_number ?? 'กำลังดำเนินการ'
    },

    isShowPayment() {
      return false
      // HACK: Hide status_2nd
      // return (
      //   this.data?.is_deposit &&
      //   this.data?.is_delivery &&
      //   !this.data?.status_2nd
      // )
    },

    thumbnailList() {
      return (
        [
          {
            id: 1,
            imageUrl: resizeImage(
              this.data?.product_main?.image_url,
              1000,
            ),
          },
        ] ?? []
      )
    },

    isBarcode() {
      return this.data?.is_barcode
    },

    barcode() {
      return this.data?.barcode
        ? `data:image/png;base64,${this.data?.barcode}`
        : null
    },
  },

  data() {
    return {
      loadingPayment: false,
      iconBank,
      error: false,
      paymentId: 'kbank',

      isBnn,
    }
  },

  async mounted() {
    await this.getData()
    if (!this.data) {
      this.$router.push({
        name: 'Error',
        params: { error: 'notfound' },
      })
    }
  },

  methods: {
    async getData() {
      const { orderId } = this.$route.params
      const apis = [
        // this.$store.dispatch('remaining/getRemainingAmount', {
        //   orderId,
        // }),
        this.$store.dispatch('order/getOrder', orderId),
      ]
      await Promise.all(apis)
    },

    async onSubmit() {
      try {
        this.loadingPayment = true
        const { orderId } = this.$route.params
        switch (this.paymentId) {
          case '2c2p':
            await this.doPayWith2C2P(orderId)
            break
          case 'kbank':
          case 'kbank-installment':
            await this.doPayWithKBank(orderId)
            break
        }
        setTimeout(() => {
          this.loadingPayment = false
        }, 5000)
      } catch (e) {
        alert(
          e?.response?.data?.message ||
            'ขออภัยในความไม่สะดวก กรุณาทำรายการใหม่อีกครั้ง',
        )
        this.loadingPayment = false
      }
    },

    async doPayWith2C2P(orderId) {
      const params = {
        order_id: orderId,
        payment_gw: this.paymentId,
        coupon_code: this.coupon?.code || undefined,
        html: 1,
      }

      const body = await axios
        .post('/payment/remaining/pay', params)
        .then(res => res.data)

      // put html to body
      const el = document.createElement('div')
      el.innerHTML = body
      document.body.appendChild(el)

      // click
      const form = document.getElementById('myform')
      form.style = 'display:none'
      typeof form.submit === 'function'
        ? form.submit()
        : form.submit.click()
    },

    async doPayWithKBank(orderId) {
      const params = {
        order_id: orderId,
        html: 0,
        coupon_code: this.coupon?.code || undefined,
        payment_gw: this.paymentId,
      }

      const body = await axios
        .post('/payment/remaining/pay', params)
        .then(res => res.data)

      const embeds = {
        ref_id: body?.data.ref_id,
        merchant_id: body?.data.merchant_id,
        apiKey: body?.data.public_apikey,
        amount: body?.data.amount,
        currency: body?.data.currency,
        smartpayId: body?.data.smartpay_id,
        smartpayTerm: body?.data.smartpay_term,
        scriptUrl: body?.data.script_url,
        endpoint: body?.data.action_url,
      }
      showKBankEmbedUI(embeds)
    },
  },
}
</script>

<style lang="stylus" scoped>

.remaining-cart-pre-order
    padding: 2rem 4rem
    @media screen and (max-width: 960px)
      padding: 1rem

.gallery
    display: flex
    justify-content: center
    padding 0 10rem 0 0
    margin-top: -2.2rem
    @media screen and (max-width: 960px)
      padding: 0
      display: flex
      justify-content: center
      width: 100%

.cart-order
  margin-top: -1rem

.text-back
  color $color-tertiary
  font-size: 1rem
  text-align: right
  cursor pointer
  display:  block
  font-weight: bold
  @media small
      display:  none
  @media medium
      display: none

.chanel-payment-grid
  display: grid
  gap: 16px
  grid-template-columns: repeat(2, 1fr)
  @media medium
     grid-template-columns: repeat(1, 1fr)
  @media small
     grid-template-columns: repeat(1, 1fr)

.hr-section
    margin-top 200px !important

.payment-label
    margin: 1rem 0
    padding: 0
    @media tablet
        padding: 0

::v-deep .btn-payment-layout
    padding: 0 !important

.checkout-action
   margin-top: 16px
   position relative
   display: flex
   justify-content: center
   .action
      display flex
      flex-direction: column
      gap: 16px
      width: 350px
   @media screen and (max-width: 768px)
    position: fixed
    width: 100%
    bottom: 0
    left: 0
    height: auto
    box-shadow: 1px 1px 2px 1px $color-gray-200;
    z-index 51
    margin-left: 0 !important
    margin-right: 0 !important
    padding-top: 1rem
    background: #fff
    .action
        width: 100%
        margin: 1.5rem 0
.full-h-screen
    height: 100vh

.margin-footer
    margin-bottom: 100px

.ref-text
    font-weight: bold
    color: $color-bnn-gray-medium-2
</style>
