<template>
  <div class="summary-cart" :class="customClass">
    <v-container>
      <div class="summary-cart-price-detail">
        <div
          v-if="couponDiscount > 0"
          class="price-summary-cart-3-lines"
        >
          <div>
            <h4>ส่วนลด</h4>
          </div>
          <div class="text-right text-discount">
            <h4>฿{{ couponDiscount | currency2 }} .-</h4>
          </div>
        </div>

        <div
          v-if="onTopDiscount > 0"
          class="price-summary-cart-3-lines"
        >
          <div>
            <h4>ส่วนลดเพิ่มเติม</h4>
          </div>
          <div class="text-right text-discount">
            <h4>฿{{ onTopDiscount | currency2 }} .-</h4>
          </div>
        </div>

        <div class="price-summary-cart-3-lines">
          <div>
            <h4>ยอดรวมสุทธิ</h4>
          </div>
          <div class="text-right price-summary-text">
            <h4>฿{{ netPriceSummary | currency2 }} .-</h4>
          </div>
        </div>

        <div
          v-if="remainingAmount > 0 && status2nd !== 'complete'"
          class="price-summary-cart-3-lines"
        >
          <div>
            <h4>ยอดคงเหลือที่ต้องชำระ</h4>
          </div>
          <div
            class="text-right price-summary-text price-text-remaining"
          >
            <h4>฿{{ remainingAmount | currency2 }} .-</h4>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      remainLoading: state => state.remaining?.isLoading,
      data: state => state.remaining.data,
    }),

    status2nd() {
      return this.data?.status_2nd
    },

    isShowPayment() {
      return false
      // return (
      //   this.data?.is_deposit &&
      //   this.data?.is_delivery &&
      //   !this.data?.status_2nd
      // )
    },

    customClass() {
      return {
        '--no-payment': !this.isShowPayment,
        '--has-payment': this.isShowPayment,
      }
    },
  },

  props: {
    color: {
      type: String,
      default: '',
    },
    height: {
      type: Number,
      default: 40,
    },
    width: {
      type: Number,
      default: 40,
    },
    select: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    couponDiscount: {
      type: Number,
      default: 0,
    },
    onTopDiscount: {
      type: Number,
      default: 0,
    },
    netPriceSummary: {
      type: Number,
      default: 0,
    },
    remainingAmount: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="stylus" scoped>
.summary-cart
  height: 120px;
  width: 100%
  bottom: 0
  left: 0
  position: fixed
  z-index 50
  background: #fff
  display: flex
  align-items: center
  box-shadow: 1px 1px 2px 1px $color-gray-200;
  @media tablet
    height: auto

@media tablet
  .--has-payment
      bottom: 0
  .--no-payment
      bottom: 0




.summary-cart-price-detail
    display: flex
    flex-direction: column
    // justify-content: space-between
    margin: 1rem 0
    font-size: 1.15rem

.price-summary-cart-3-lines
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 1 rem

.price-text-remaining
  color: $color-bnn

.text-discount
  color $color-bnn-red
</style>
