<template>
  <div v-bind:class="['btn-payment-layout', { selected: selected }]">
    <div
      class="btn-payment"
      v-bind:class="{ select: selected, 'has-error': hasError }"
      @click="selected ? false : $emit('click')"
    >
      <v-col cols="12">
        <v-row v-bind="{ justify: 'start', 'no-gutters': true }">
          <v-col cols="2" class="align-self-center">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex align-items-center justify-start"
              >
                <img
                  width="50px"
                  height="50px"
                  class="w-full fit-contain"
                  :src="image"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="10" class="align-self-center px-2">
            <v-row no-gutters>
              <!-- <div class="d-flex flex-column"> -->
              <v-row no-gutters>
                <v-col
                  cols="12"
                  class="text-left text-btn-payment font-bold align-self-center"
                >
                  {{ name }}
                </v-col>
                <v-col
                  v-if="label"
                  cols="12"
                  class="align-self-center"
                >
                  <div class="text-left text-btn-payment">
                    {{ label }}
                  </div>
                </v-col>
              </v-row>
              <!-- <v-col cols="5" class="align-self-center">
                    <div v-if="delivery" class="text-right text-lg">
                      ฿{{ item.price_ship | currency }}
                    </div>
                    <div v-if="!delivery" class="text-right text-lg">
                      ฿{{ item.price_store | currency }}
                    </div>
                  </v-col> -->
            </v-row>
          </v-col>
          <v-col
            v-if="selected && items && items.length > 0"
            cols="12"
            class="align-self-center mt-3"
          >
            <label class="text-sm">
              แผนการผ่อนชำระ <span class="text-red">*</span>
            </label>
            <v-select
              v-model="installmentPlan"
              :dense="true"
              :items="items"
              :color="colorBnn['color-bnn']"
              class="text-sm"
              background-color="white"
              item-text="terms"
              item-value="item"
              placeholder="เลือกแผนการผ่อนชำระ"
              ref="items"
              outlined
              hide-details
              return-object
              @change="handleChange"
            >
              <template slot="selection" slot-scope="data">
                ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                {{ Number(data.item.interest) }}%
              </template>
              <template slot="item" slot-scope="data">
                ผ่อนชำระ {{ data.item.terms }} เดือน ดอกเบี้ย
                {{ Number(data.item.interest) }}%
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import colorBnn from '@/config/color'

export default {
  name: 'ButtonPayment',
  props: {
    name: String,
    label: String,
    image: String,
    selected: Boolean,
    hasError: Boolean,
    items: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      colorBnn,
      installmentPlan: this.items ? this.items[0] : null,
    }
  },
  methods: {
    handleChange() {
      this.$emit('updateInstallmentPlan', this.installmentPlan)
    },
  },
  watch: {
    selected(newVal) {
      if (newVal && this.items?.length > 0) {
        this.installmentPlan = this.items[0] ?? null
        this.handleChange()
      }
    },
  },
}
</script>

<style lang="stylus">

.btn-payment-layout
    width 100%
    // padding 0 1rem
    @media medium
        width 100%
        // padding 0.5rem 0
    @media small
        width 100%
        // padding 0.5rem 0
.btn-payment
    width 100%
    background white
    cursor pointer
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    border-radius: 8px;
    height: auto;
    transition: 0.2s ease-in-out;
    @media (hover: hover) and (pointer: fine)
        &:hover
          border-color: transparent;
          box-shadow: 0 0 0 2px $color-bnn-border;
          transition: 0.2s ease-in-out;
          // border-color: $color-dark-gray;
    .text-btn-payment
        font-size 1rem
    @media medium
        .text-btn-payment
            font-size 1.2rem
    @media small
        .text-btn-payment
            font-size 0.8rem
.btn-payment.select
	box-shadow: 0 0 0 2px $color-bnn;
	border-color: transparent;
     // box-shadow: 0px 2px 3px #00000029;
.btn-payment.has-error
     border-color: red;
</style>
